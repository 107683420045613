
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "a36919e7-0361-4860-9ddc-6131ddd7a670"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/src/app/src/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
